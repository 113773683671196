import React from 'react'
import { AiOutlineLeft } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight } from '@react-icons/all-files/ai/AiOutlineRight';
import usePagination, { DOTS } from '../hooks/usePagination';
import "../assets/css/pagination.css";

const Pagination = ({ totalData, currentPage, setCurrentPage, recordsPerPage, setIsLoading }) => {
    const paginationRange = usePagination({ totalData, currentPage, recordsPerPage })
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= lastPage && newPage !== currentPage) {
            setIsLoading(true);
            setCurrentPage(newPage);
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }
    };
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }
    let lastPage = paginationRange?.length ? paginationRange[paginationRange.length - 1] : 1;
    return (
        <nav>
            <ul className='pagination-wrapper'>
                <li className="page-item">
                    <a className={`page-link btn ${currentPage == 1 ? 'btn-disable' : ''}`} onClick={() => handlePageChange(currentPage - 1)} href='#'>  <AiOutlineLeft /> </a>
                </li>
                {paginationRange?.map((pgNumber, index) => {
                    if (pgNumber === DOTS) {
                        return <li className="dots" key={index}>{DOTS}</li>;
                    }
                    return (<li key={index}
                        className={`page-item ${currentPage == pgNumber ? 'active' : ''} `}>
                        <a onClick={() => handlePageChange(pgNumber)} className='page-link' href='#'>
                            {pgNumber}
                        </a>
                    </li>)

                })}
                <li className="page-item">
                    <a className={`page-link btn ${currentPage == lastPage ? 'btn-disable' : ''}`} onClick={() => handlePageChange(currentPage + 1)} href='#'>
                        <AiOutlineRight />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination