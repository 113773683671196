import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { Skeleton } from '@mui/material';
import Pagination from './pagination';
import ShopifyHeader from './shopify-header';
import useDebounce from '../hooks/useDebounce';
import shopifyDefaultImage from "../assets/images/shopify-bag-icon.png";
import "../assets/css/shopify-product-list.css";

const recordsPerPage = 16;
const ShopifyProductList = ({ zoneContent, shopifyProductDetails, menuData, isLongWebAddress, sectionThemeObj }) => {
    const reforgedMenu = menuData?.length && JSON.parse(menuData?.[0]?.MenuDTO?.ContentObject)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [shopifyProductsLists, setShopifyProductsLists] = useState([]);
    const [filters, setFilters] = useState({ productTags: [], productVendors: [] });
    const [viewDetails, setViewDetails] = useState({
        isTag: true,
        isVendor: true
    })
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const userSearch = useDebounce(searchInput, 300);
    const currentRoute = location?.pathname?.split("/")?.[1]?.replace(/-/g, "_")?.toLowerCase() || '';
    const createdRoute = zoneContent?.[0]?.ScreenDTO?.Path?.split("/")?.[1]?.replace(/-/g, "_")?.toLowerCase() || '';
    const filteredData = shopifyProductDetails?.find(value => value?.hasOwnProperty(currentRoute));
    const shopifyProductData = !isLongWebAddress
        ? filteredData?.[currentRoute]
        : location?.pathname !== "/"
            ? filteredData?.[currentRoute]
            : shopifyProductDetails?.find(value => value?.hasOwnProperty(createdRoute))?.[createdRoute];

    const currentRecords = useMemo(() => {
        const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
        const indexOfLastRecord = indexOfFirstRecord + recordsPerPage;
        return shopifyProductsLists?.slice(indexOfFirstRecord, indexOfLastRecord);
    }, [currentPage, shopifyProductsLists])

    const FiltersItem = ({ filterName }) => {
        const isTagFilter = filterName === "tags";
        const itemList = isTagFilter ? filters?.productTags : filters?.productVendors;
        const displayedItems = viewDetails[isTagFilter ? 'isTag' : 'isVendor'] ? itemList?.length > 7 ? itemList?.slice(0, 7) : itemList : itemList;
        const linkText = viewDetails[isTagFilter ? 'isTag' : 'isVendor'] ? "View more" : "View less";
        const handleClick = () => {
            const key = isTagFilter ? 'isTag' : 'isVendor';
            setViewDetails(prev => ({
                ...prev,
                [key]: !prev[key]
            }));
        };
        return (
            <div className='side-nav-collapse'>
                {displayedItems?.map((item, index) => (
                    <TagItem key={index} item={item} filterName={filterName} />
                ))}
                {itemList?.length > 7 && (
                    <a onClick={handleClick} className='show-more' style={{ color: sectionThemeObj?.fontColor || "" }}>
                        {linkText}
                    </a>
                )}
            </div>
        );
    };

    const TagItem = ({ item, filterName }) => (
        <label htmlFor={filterName === "tags" ? `${item}-tag` : `${item}-vendor`} className='side-nav-item'>
            <input
                type='checkbox'
                id={filterName === "tags" ? `${item}-tag` : `${item}-vendor`}
                name={item}
                value={item}
                onChange={(e) => filterName === "tags" ? handleTagChecked(e) : handleVendorChecked(e)}
                checked={filterName === "tags" ? selectedTags?.includes(item) : selectedVendors?.includes(item)}
            />
            <div>{item}</div>
        </label>
    );

    const FilterSection = ({ title, setSelectedSection, selectedSection, filterName }) => {
        return (
            <>
                <div className="side-nav-heading">
                    {title}
                    <div className={`clear-btn ${selectedSection.length ? "clear-btn-text" : ""}`} onClick={() => setSelectedSection([])}>Clear</div>
                </div>
                <FiltersItem filterName={filterName} />
            </>
        )
    }

    const handleTagChecked = (e) => {
        const { value, checked } = e.target
        setSelectedTags((prevChecked) =>
            checked ? [...prevChecked, value] : prevChecked?.filter(tag => tag !== value)
        )
    }
    const handleVendorChecked = (e) => {
        const { value, checked } = e.target
        setSelectedVendors((prevChecked) =>
            checked ? [...prevChecked, value] : prevChecked?.filter(vendor => vendor !== value)
        )
    }

    const setPage = () => {
        const shouldResetPage = userSearch || selectedTags.length || selectedVendors.length;
        if (shouldResetPage) {
            setCurrentPage(1);
        } else {
            const data = localStorage.getItem("pnum");
            if (data !== null) {
                setCurrentPage(JSON.parse(data));
            }
        }
    };

    const filtersValue = () => {
        let validateTags = new Set();
        let validateVendor = new Set();
        shopifyProductData?.forEach(value => {
            if (value?.tags !== "") {
                value?.tags?.split(",")?.forEach(item => validateTags?.add(item?.trim()))
            }
            if (value?.vendor !== "") {
                validateVendor?.add(value?.vendor)
            }
        });
        setFilters({
            productTags: [...validateTags],
            productVendors: [...validateVendor],
        });
    };

    const filteredProductData = useMemo(() => {
        if (!shopifyProductData?.length) return [];
        const applyFilters = (shopifyProduct) => {
            return shopifyProduct?.filter(item => {
                const itemTags = item?.tags?.split(",")?.map(tag => tag?.trim());
                const tagsMatch = selectedTags?.length === 0 || selectedTags?.every(tag => itemTags?.includes(tag));
                const vendorMatch = selectedVendors?.length === 0 || selectedVendors?.includes(item?.vendor);
                const titleMatch = item?.title?.toLowerCase()?.includes(userSearch?.toLowerCase());
                return tagsMatch && vendorMatch && titleMatch;
            });
        };

        return (userSearch || selectedTags?.length > 0 || selectedVendors?.length > 0)
            ? applyFilters(shopifyProductData)
            : shopifyProductData;

    }, [userSearch, selectedTags, selectedVendors, shopifyProductData]);

    useEffect(() => {
        setPage();
    }, [userSearch, selectedTags, selectedVendors]);

    useEffect(() => {
        if (JSON.stringify(shopifyProductsLists) !== JSON.stringify(filteredProductData)) {
            setShopifyProductsLists(filteredProductData);
        }
    }, [filteredProductData]);

    useEffect(() => {
        if (shopifyProductData?.length) {
            filtersValue()
        }
    }, []);

    useEffect(() => {
        if (shopifyProductData?.length < recordsPerPage) {
            setCurrentPage(1);
        }
    }, []);

    useEffect(() => {
        const savedPage = localStorage.getItem("pnum");
        if (savedPage !== JSON.stringify(currentPage)) {
            localStorage.setItem("pnum", JSON.stringify(currentPage));
        }
    }, [currentPage]);

    return (
        <div className={!isLongWebAddress ? "product-list-wrapper" : ""} style={{ background: sectionThemeObj?.background || "", color: sectionThemeObj?.fontColor || "", fontFamily: sectionThemeObj?.fontFamily || "" }}>
            <ShopifyHeader reforgedMenu={reforgedMenu} isShopifyList setSearchInput={setSearchInput} isLongWebAddress={isLongWebAddress} sectionThemeObj={sectionThemeObj} searchInput={searchInput} />
            <div className="main-container">
                <div className='layout-row'>
                    <div className='side-nav' style={{ borderRight: `1px solid ${sectionThemeObj?.borderColor || "#efeff4"}` }}>
                        {filters?.productTags?.length > 0 && (
                            <FilterSection title={"Tags"} setSelectedSection={setSelectedTags} selectedSection={selectedTags} filterName={"tags"} />
                        )}
                        {filters?.productVendors?.length > 0 && (
                            <FilterSection title={"Vendor"} setSelectedSection={setSelectedVendors} selectedSection={selectedVendors} />
                        )}
                    </div>
                    <div className='product-top-area'>
                        <Pagination
                            totalData={shopifyProductsLists?.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            recordsPerPage={recordsPerPage}
                            setIsLoading={setIsLoading}
                        />
                        {shopifyProductsLists?.length > recordsPerPage + 1 && <hr />}
                        <div className='products-container'>
                            <div className={`product-details ${shopifyProductsLists?.length < recordsPerPage + 1 && "disable-line"}`} >
                                {shopifyProductsLists?.length ?
                                    currentRecords?.map((values, index) =>
                                        isLoading ?
                                            (
                                                <Skeleton variant="rounded" width={312} height={450} key={index} />
                                            )
                                            : (
                                                <div className='product-info-wrapper' style={{ border: `1px solid ${sectionThemeObj?.borderColor || "#efeff4"}` }} key={index} onClick={() => navigate(`/${createdRoute?.replace(/_/g, "-")}/${values?.handle}`)}>
                                                    <div className='product-section'>
                                                        <div className='product-img-wrapper'>
                                                            <img src={values?.images?.[0]?.src || shopifyDefaultImage} alt="image" className={`product-img ${!values?.images?.length ? "default-image" : ""}`} />
                                                        </div>
                                                        <div className='product-name'>{values?.title}</div>
                                                        <div>
                                                            <div className="badge bg-light text-gray border border-gray p-1 mr-2 w-auto">{values?.vendor}</div>
                                                            <div className="badge bg-light text-primary border border-primary p-1 w-auto">{values?.productType}</div>
                                                        </div>
                                                        <div>{`$ ${values?.variants[0]?.price || 0}`}</div>
                                                        <div className='text'>{values?.tags}</div>
                                                    </div>
                                                    <div className='line' style={{ borderBottom: `1px solid ${sectionThemeObj?.borderColor || "#efeff4"}` }} />
                                                </div>
                                            )
                                    ) :
                                    <div className='no-data'>No Data Found</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopifyProductList;
