import React, { useEffect, useState } from 'react';

const useDebounce = (inputValue, delay) => {
    const [value, setValue] = useState(inputValue)

    let timerId;
    useEffect(() => {
        timerId = setTimeout(() => {
            setValue(inputValue)
        }, delay)

        return () => { clearTimeout(timerId) }
    }, [inputValue, delay])
    return value
}

export default useDebounce;
