import React, { useState } from "react";
import Client from 'shopify-buy';
import ShopPayIcon from "../../assets/svg/shoppay.svg"
import "../../assets/css/shopify-product-details.css"

const client = Client.buildClient({
    domain: `${process.env.GATSBY_STORE_URL}`,
    storefrontAccessToken: `${process.env.GATSBY_STORE_FRONT_ACCESS_TOKEN}`,
});
const ShopPayButton = ({ variantId, quantity }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleShopPayRedirect = async () => {
        setIsLoading(true);
        try {
            const checkout = await client.checkout.create();
            const lineItems = [{ variantId, quantity }];
            await client.checkout.addLineItems(checkout.id, lineItems);
            
            if (checkout.webUrl) {
                setError("");
                window.open(checkout.webUrl, "_blank");
            }

        } catch (err) {
            setError("Error creating checkout.");
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <button
                onClick={handleShopPayRedirect}
                className="shop-pay-button"
            >
                {isLoading ? "Creating Checkout..." : <> <span>Buy with </span> <img src={ShopPayIcon} alt="ShopPayIcon" /></>}
            </button>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default ShopPayButton;


