import React, { useState } from 'react';
import ShopPayButton from './ShopPayButton';

const BuyProduct = ({ product }) => {
    const [quantityItem, setQuantityItem] = useState(1);
    const checkProductAvailable = product?.publishedScope === "web" || product?.publishedAt === null

    const handleQuantity = (operation) => {
        setQuantityItem((prev) => operation === "minus" ? prev - 1 : prev + 1)
    }
    return (
        <>
            {!checkProductAvailable &&
                <div className="quantity">
                    <button className="quantity-btn minus" onClick={() => handleQuantity("minus")} disabled={quantityItem === 1}>&minus;</button>
                    <input type="number" className="input-box" value={quantityItem} min="1" onChange={(e) => setQuantityItem(Math.max(1, +e.target.value))} />
                    <button className="quantity-btn plus" onClick={handleQuantity}>+</button>
                </div>
            }
            <a className={`common_btn red_bg buy-now ${product?.publishedScope === "web" || product?.publishedAt === null ? "disabled" : ""}`} target="_blank" href={`https://store.wogd.com/products/${product?.handle}`}>
                <span>{checkProductAvailable ? "Not For Sale" : "Buy Now"}</span>
            </a>
            {!checkProductAvailable && <ShopPayButton variantId={product?.variants[0]?.adminGraphQLAPIId} quantity={quantityItem} />}
        </>
    );
}

export default BuyProduct;
